import Axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
Axios.defaults.baseURL = API_URL;
Axios.defaults.withCredentials = true;

export class HttpService {
  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url, params) =>
    await this.request(this.getOptionsConfig("get", url, null, params));

  post = async (url, data, params) =>
    await this.request(this.getOptionsConfig("post", url, data, params));

  put = async (url, data) =>
    await this.request(this.getOptionsConfig("put", url, data));

  patch = async (url, data) =>
    await this.request(this.getOptionsConfig("patch", url, data));

  delete = async (url, params) =>
    await this.request(this.getOptionsConfig("delete", url, null, params));

  upload = async (url, data, params) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    try {
      return await this.request(
        this.getOptionsConfig("post", url, data, params, headers),
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  getOptionsConfig = (method, url, data, params, headers) => {
    const defaultHeaders = {
      "Content-Type": "application/vnd.api+json",
      Accept: "application/vnd.api+json",
    };

    const mergedHeaders = {
      ...defaultHeaders,
      ...(headers || {}),
    };

    return {
      method,
      url,
      data,
      params,
      headers: mergedHeaders,
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
        .request(options)
        .then((res) => resolve(res))
        .catch((error) => reject(error.response));
    });
  }
}

const httpServiceInstance = new HttpService();
export default httpServiceInstance;
