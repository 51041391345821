import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

import MDTypography from "components/MDTypography";

export default function OrderLineItems({ orderLineItems }) {
  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f8f9fa",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table aria-label="order lines table">
        <TableBody>
          {orderLineItems.map((orderLineItem) => (
            <StyledTableRow key={orderLineItem.id}>
              <TableCell scope="row">
                <MDTypography variant="body2">
                  {orderLineItem.item_quantity} x{" "}
                  {orderLineItem.item_description} &nbsp;
                  {orderLineItem.is_completed ? (
                    <Icon color="success">check</Icon>
                  ) : null}
                </MDTypography>
                {orderLineItem.subitems.length !== 0 && (
                  <Table aria-label="subitems table" size="small">
                    <TableBody>
                      {orderLineItem.subitems.map((subitem) => (
                        <TableRow key={subitem.id} sx={{ td: { border: 0 } }}>
                          <TableCell scope="row" sx={{ padding: "0 1.25rem" }}>
                            <MDTypography variant="caption">
                              {subitem.item_quantity} x{" "}
                              {subitem.item_description} &nbsp;
                              {subitem.is_completed ? (
                                <Icon color="success">check</Icon>
                              ) : null}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableCell>
              <TableCell sx={{ verticalAlign: "top", textAlign: "right" }}>
                <MDTypography variant="body2" sx={{ fontWeight: 700 }}>
                  &#8369; {orderLineItem.item_price.toFixed(2)}
                </MDTypography>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

OrderLineItems.propTypes = {
  orderLineItems: PropTypes.array.isRequired,
};
