import { useCallback, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MenuItem from "@mui/material/MenuItem";

// @mui-x
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import GoogleMapsLink from "components/GoogleMapsLink";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Data
import ordersTableData from "pages/CustomerOrders/data/ordersTableData";

import HttpService from "services/http-service";

const CustomerOrders = () => {
  const { customerId } = useParams();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [statusValue, setStatusValue] = useState(-1);
  const [filterOrderId, setOrderId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [cleared, setCleared] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);

  const { columns, rows, fetchData } = ordersTableData();

  const handleStatusChange = (event) => {
    setStatusValue(event.target.value);
  };

  const handleOrderIDChange = (event) => {
    setOrderId(event.target.value);
  };

  const statusOptions = [
    {
      value: "-1",
      label: "All",
    },
    {
      value: "1",
      label: "Order Received",
    },
    {
      value: "2",
      label: "Waiting for Payment",
    },
    {
      value: "3",
      label: "Payment Confirmed",
    },
    {
      value: "4",
      label: "Preparing Dishes",
    },
    {
      value: "5",
      label: "For Packaging",
    },
    {
      value: "6",
      label: "Waiting for Dispatch",
    },
    {
      value: "7",
      label: "Out for Delivery",
    },
    {
      value: "8",
      label: "Completed",
    },
    {
      value: "9",
      label: "Cancelled",
    },
    {
      value: "10",
      label: "On-Hold",
    },
    {
      value: "11",
      label: "Refund",
    },
  ];

  const fetchCustomerDetails = useCallback(async () => {
    try {
      const response = await HttpService.get(
        "/kitchen/customers/" + customerId,
      );
      setCustomerDetails(response.data);
    } catch (error) {
      navigate("/customers");
      console.log("Error fetching data:", error);
    }
  }, [customerId, navigate]);

  useEffect(() => {
    fetchCustomerDetails();
  }, [customerId, fetchCustomerDetails]);

  useEffect(() => {
    if (isInitialLoading) {
      let fetchDataParams = {
        filters: {
          status: statusValue,
          order_id: filterOrderId,
          customer_id: customerId,
          delivery_date: deliveryDate
            ? deliveryDate.format("YYYY-MM-DD")
            : undefined,
        },
      };
      setIsInitialLoading(false);
      try {
        fetchData(fetchDataParams);
      } catch (error) {
        console.log(error);
      }
    }
  }, [
    fetchData,
    customerId,
    deliveryDate,
    filterOrderId,
    statusValue,
    isInitialLoading,
  ]);

  const submitHandler = async (e) => {
    e.preventDefault();

    let fetchDataParams = {
      filters: {
        status: statusValue,
        order_id: filterOrderId,
        customer_id: customerId,
        delivery_date: deliveryDate
          ? deliveryDate.format("YYYY-MM-DD")
          : undefined,
      },
    };

    try {
      fetchData(fetchDataParams);
    } catch (error) {
      console.log(error);
    }
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    const words = name.split(" ");
    let initials = "";

    if (words.length > 1) {
      initials = `${words[0][0]}${words[words.length - 1][0]}`;
    } else {
      initials = name[0];
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumbRoutes={["customers", customerId, "orders"]} />
      <MDBox pt={6} pb={3} sx={{ minHeight: "calc(100vh - 150px)" }}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Orders
                </MDTypography>
              </MDBox>
              <MDBox mx={4} mb={4}>
                {typeof customerDetails === "object" &&
                  customerDetails !== null && (
                    <Grid container spacing={3} alignItems="center">
                      <Grid>
                        <MDAvatar
                          {...stringAvatar(customerDetails.name)}
                          variant="circle"
                          size="lg"
                          shadow="xl"
                        />
                      </Grid>
                      <Grid>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                          <MDTypography variant="h5" fontWeight="medium">
                            {customerDetails.name}
                          </MDTypography>
                          <MDTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                          >
                            {customerDetails.contact_number} /{" "}
                            {customerDetails.address}
                          </MDTypography>
                        </MDBox>
                        <MDBox mt={2}>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="flex-end"
                          >
                            <Link
                              href={`sms:${customerDetails.contact_number}`}
                              underline="none"
                            >
                              <Icon component={ChatRoundedIcon} />
                            </Link>
                            <Link
                              href={`tel:${customerDetails.contact_number}`}
                              underline="none"
                            >
                              <Icon component={CallRoundedIcon} />
                            </Link>
                            <GoogleMapsLink address={customerDetails.address}>
                              <Icon component={LocationOnRoundedIcon} />
                            </GoogleMapsLink>
                          </Stack>
                        </MDBox>
                      </Grid>
                    </Grid>
                  )}
              </MDBox>
            </Card>
          </Grid>
          <Grid size={12}>
            <Card>
              <MDBox
                component="form"
                role="form"
                method="POST"
                onSubmit={submitHandler}
              >
                <Grid container spacing={2} mt={2} mb={2} mx={3}>
                  <Grid size={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Delivery Date"
                        value={deliveryDate}
                        onChange={(newValue) => setDeliveryDate(newValue)}
                        slotProps={{
                          field: {
                            clearable: true,
                            onClear: () => setCleared(true),
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid size={2}>
                    <MDInput
                      select
                      placeholder="Status"
                      value={statusValue}
                      defaultValue={statusValue}
                      label="Status"
                      fullWidth
                      onChange={handleStatusChange}
                      variant="outlined"
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MDInput>
                  </Grid>
                  <Grid size={2}>
                    <MDInput
                      label="Order ID"
                      placeholder="Order ID"
                      fullWidth
                      onChange={handleOrderIDChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid size={4}>
                    <MDButton variant="contained" color="primary" type="submit">
                      Filter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid size={12}>
            <Card sx={{ marginTop: "10px" }}>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  canSearch={false}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CustomerOrders;
