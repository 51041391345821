import { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "context";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ tiv, children }) => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (
      (!authContext.isAuthenticated ||
        !authContext.tiv ||
        tiv < authContext.tiv) &&
      authContext.isLoaded
    ) {
      authContext.logout();
    }
  }, [authContext, tiv]);

  if (!authContext.isAuthenticated) {
    return <Navigate to="/login" replace={true} />;
  }

  if (tiv < authContext.tiv) {
    return <Navigate to="/login" replace={true} />;
  }

  return children;
};

// Prop types validation
ProtectedRoute.propTypes = {
  tiv: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
