import { useCallback, useEffect, useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

// react-to-print
import { useReactToPrint } from "react-to-print";

// @mui material components
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import GoogleMapsLink from "components/GoogleMapsLink";
import Footer from "components/Footer";

import OrderLineItems from "./components/OrderLineItems";
import PrintableContents from "./components/PrintableContents";
import OrderActivities from "./components/OrderActivities";
import OrderPhotos from "./components/OrderPhotos";

import HttpService from "services/http-service";

const OrderDetails = () => {
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const [orderDetails, setOrderDetails] = useState(null);
  const [orderLinesCopy, setCopyOrderLines] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState(null);
  const { orderId, externalOrderId } = useParams();

  const navigate = useNavigate();
  const componentRef = useRef();

  const handlePrintOrder = useReactToPrint({
    contentRef: componentRef,
  });

  const convertToUnicode = (inputString) => {
    const conversionMap = {
      0: "𝟎",
      1: "𝟏",
      2: "𝟐",
      3: "𝟑",
      4: "𝟒",
      5: "𝟓",
      6: "𝟔",
      7: "𝟕",
      8: "𝟖",
      9: "𝟗",
      a: "𝐚",
      b: "𝐛",
      c: "𝐜",
      d: "𝐝",
      e: "𝐞",
      f: "𝐟",
      g: "𝐠",
      h: "𝐡",
      i: "𝐢",
      j: "𝐣",
      k: "𝐤",
      l: "𝐥",
      m: "𝐦",
      n: "𝐧",
      o: "𝐨",
      p: "𝐩",
      q: "𝐪",
      r: "𝐫",
      s: "𝐬",
      t: "𝐭",
      u: "𝐮",
      v: "𝐯",
      w: "𝐰",
      x: "𝐱",
      y: "𝐲",
      z: "𝐳",
      A: "𝐀",
      B: "𝐁",
      C: "𝐂",
      D: "𝐃",
      E: "𝐄",
      F: "𝐅",
      G: "𝐆",
      H: "𝐇",
      I: "𝐈",
      J: "𝐉",
      K: "𝐊",
      L: "𝐋",
      M: "𝐌",
      N: "𝐍",
      O: "𝐎",
      P: "𝐏",
      Q: "𝐐",
      R: "𝐑",
      S: "𝐒",
      T: "𝐓",
      U: "𝐔",
      V: "𝐕",
      W: "𝐖",
      X: "𝐗",
      Y: "𝐘",
      Z: "𝐙",
    };

    const convertedString = inputString
      .split("")
      .map((char) => conversionMap[char] || char)
      .join("");
    return convertedString;
  };

  const processOrderLinesClipboard = useCallback((orderLines) => {
    let orderLinesText = "";

    orderLines.forEach((orderLine) => {
      if (orderLine.subitems.length === 0) {
        orderLinesText += `✅ ${orderLine.item_quantity} ${orderLine.item_description}\n`;
      } else {
        orderLine.subitems.forEach((subitem) => {
          orderLinesText += `✅ ${subitem.item_quantity} ${subitem.item_description}\n`;
        });
      }
    });

    let orderLinesUnicodeText = convertToUnicode(orderLinesText.trim());
    setCopyOrderLines(orderLinesUnicodeText);

    // setCopyOrderLines(orderLinesText.trim());
  }, []);

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar("", false, "success");
  };

  const copyOrderLines = () => {
    navigator.clipboard.writeText(orderLinesCopy);
    setSnackbar("Copied to clipboard!", true, "success");
  };

  const setSnackbar = (snackbarMessage, show, severity) => {
    setSnackbarMessage(snackbarMessage);
    setOpenSnackbar(show);
    setSnackBarSeverity(severity);
  };

  const fetchOrderDetails = useCallback(async () => {
    try {
      const response = await HttpService.get("/kitchen/orders/" + orderId);
      setOrderDetails(response.data);
      processOrderLinesClipboard(response.data.order_line_items);
    } catch (error) {
      navigate("/orders");
      console.log("Error fetching data:", error);
    }
  }, [orderId, processOrderLinesClipboard, navigate]);

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId, externalOrderId, fetchOrderDetails]);

  const formatTime = (timeString) => {
    // Create a dummy date object
    const dummyDate = new Date(2000, 0, 1);

    // Extract hours and minutes from the time string
    const [hours, minutes] = timeString.split(":");

    // Set the hours and minutes on the dummy date object
    dummyDate.setHours(parseInt(hours, 10));
    dummyDate.setMinutes(parseInt(minutes, 10));

    // Use Intl.DateTimeFormat to format the time
    const formattedTime = new Intl.DateTimeFormat("en-PH", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(dummyDate);

    return formattedTime;
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const stringAvatar = (name) => {
    const words = name.split(" ");
    let initials = "";

    if (words.length > 1) {
      initials = `${words[0][0]}${words[words.length - 1][0]}`;
    } else {
      initials = name[0];
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials,
    };
  };

  return (
    <DashboardLayout>
      <DashboardNavbar breadCrumbRoutes={["orders", externalOrderId]} />
      <MDBox pt={6} pb={3} sx={{ minHeight: "calc(100vh - 150px)" }}>
        <Grid container spacing={3}>
          <Grid size={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  {typeof orderDetails === "object" && orderDetails !== null ? (
                    `#${externalOrderId}`
                  ) : (
                    <Skeleton animation="wave" />
                  )}
                </MDTypography>
              </MDBox>
              <MDBox mx={{ xs: 3, md: 4 }} mb={4}>
                <Grid container spacing={3} alignItems="center">
                  <Grid sx={{ display: { xs: "none", sm: "block" } }}>
                    {typeof orderDetails === "object" &&
                    orderDetails !== null ? (
                      <MDAvatar
                        {...stringAvatar(orderDetails.customer_name)}
                        variant="circle"
                        size="lg"
                        shadow="xl"
                      />
                    ) : (
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={58}
                        height={58}
                      />
                    )}
                  </Grid>
                  <Grid>
                    <MDBox height="100%" mt={0.5} lineHeight={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {typeof orderDetails === "object" &&
                        orderDetails !== null ? (
                          <Link
                            href={`/customers/${orderDetails.customer_id}`}
                            to={`/customers/${orderDetails.customer_id}`}
                            underline="always"
                          >
                            {orderDetails.customer_name}
                          </Link>
                        ) : (
                          <Skeleton animation="wave" width={150} />
                        )}
                      </MDTypography>
                      <MDTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        {typeof orderDetails === "object" &&
                        orderDetails !== null ? (
                          `${orderDetails.customer_contact_number} / ${orderDetails.customer_address}`
                        ) : (
                          <Skeleton animation="wave" width={450} />
                        )}
                      </MDTypography>
                    </MDBox>
                    <MDBox mt={2}>
                      <Stack direction="row" spacing={2} alignItems="flex-end">
                        {typeof orderDetails === "object" &&
                        orderDetails !== null ? (
                          <>
                            <Link
                              href={`sms:${orderDetails.customer_contact_number}`}
                              underline="none"
                            >
                              <Icon component={ChatRoundedIcon} />
                            </Link>
                            <Link
                              href={`tel:${orderDetails.customer_contact_number}`}
                              underline="none"
                            >
                              <Icon component={CallRoundedIcon} />
                            </Link>
                            <GoogleMapsLink
                              address={orderDetails.customer_address}
                            >
                              <Icon component={LocationOnRoundedIcon} />
                            </GoogleMapsLink>
                          </>
                        ) : (
                          <>
                            <Skeleton animation="wave" width={20} />
                            <Skeleton animation="wave" width={20} />
                            <Skeleton animation="wave" width={20} />
                          </>
                        )}
                      </Stack>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <MDBox mb={4}>
              <Card>
                <MDBox mx={{ xs: 3, md: 4 }} mb={4}>
                  <MDBox py={2}>
                    <MDTypography variant="h5" textTransform="capitalize">
                      {typeof orderDetails === "object" &&
                      orderDetails !== null ? (
                        "Shipping Details"
                      ) : (
                        <Skeleton animation="wave" width={150} />
                      )}
                    </MDTypography>
                  </MDBox>
                  <MDBox mx={{ xs: 0, mb: 2 }}>
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                            mb={-2}
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Contact Person"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>
                                {orderDetails.shipping_contact_person ||
                                  orderDetails.customer_name}
                              </>
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={6}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Contact Number"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>
                                {orderDetails.shipping_contact_number ||
                                  orderDetails.customer_contact_number}
                              </>
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={12}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Address"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>
                                {orderDetails.shipping_address ||
                                  orderDetails.customer_address}
                              </>
                            ) : (
                              <Skeleton animation="wave" width={350} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={4}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Delivery Date"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>{orderDetails.delivery_date}</>
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={4}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Dispatch Time"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>{formatTime(orderDetails.dispatch_time)}</>
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={4}>
                        <MDBox>
                          <MDTypography
                            variant="caption"
                            fontWeight="regular"
                            textTransform="capitalize"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              "Delivery ETA"
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography variant="body2" fontWeight="medium">
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>{formatTime(orderDetails.delivery_eta)}</>
                            ) : (
                              <Skeleton animation="wave" width={150} />
                            )}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid size={12}>
                        <MDBox>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="flex-end"
                          >
                            {typeof orderDetails === "object" &&
                            orderDetails !== null ? (
                              <>
                                <Link
                                  href={`sms:${orderDetails.customer_contact_number}`}
                                >
                                  <Icon component={ChatRoundedIcon} />
                                </Link>
                                <Link
                                  href={`tel:${orderDetails.customer_contact_number}`}
                                >
                                  <Icon component={CallRoundedIcon} />
                                </Link>
                                <GoogleMapsLink
                                  address={orderDetails.customer_address}
                                >
                                  <Icon component={LocationOnRoundedIcon} />
                                </GoogleMapsLink>
                              </>
                            ) : (
                              <>
                                <Skeleton animation="wave" width={20} />
                                <Skeleton animation="wave" width={20} />
                                <Skeleton animation="wave" width={20} />
                              </>
                            )}
                          </Stack>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
            <MDBox mb={4}>
              <Card>
                <MDBox mx={{ xs: 3, md: 4 }} mb={4}>
                  <Card sx={{ boxShadow: "none" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography
                        py={2}
                        variant="h5"
                        textTransform="capitalize"
                      >
                        {typeof orderDetails === "object" &&
                        orderDetails !== null ? (
                          "Items"
                        ) : (
                          <Skeleton animation="wave" width={150} />
                        )}
                      </MDTypography>
                      <MDBox>
                        {typeof orderDetails === "object" &&
                          orderDetails !== null && (
                            <MDButton onClick={() => copyOrderLines()}>
                              <Icon>copy</Icon>
                            </MDButton>
                          )}
                      </MDBox>
                    </MDBox>
                    <MDBox px={{ xs: 0, md: 2 }}>
                      {typeof orderDetails === "object" &&
                      orderDetails !== null ? (
                        <OrderLineItems
                          orderLineItems={orderDetails.order_line_items}
                        />
                      ) : (
                        <TableContainer sx={{ boxShadow: "none" }}>
                          <Table aria-label="order lines table">
                            <TableBody>
                              <TableRow key="skeleton-1">
                                <TableCell scope="row" width="90%">
                                  <MDTypography variant="body2">
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    textAlign: "right",
                                  }}
                                  width="10%"
                                >
                                  <MDTypography
                                    variant="body2"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                              <TableRow key="skeleton-2">
                                <TableCell scope="row">
                                  <MDTypography variant="body2">
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    textAlign: "right",
                                  }}
                                >
                                  <MDTypography
                                    variant="body2"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                              <TableRow key="skeleton-3">
                                <TableCell scope="row">
                                  <MDTypography variant="body2">
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    verticalAlign: "top",
                                    textAlign: "right",
                                  }}
                                >
                                  <MDTypography
                                    variant="body2"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    <Skeleton animation="wave" width="100%" />
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </MDBox>
                  </Card>
                </MDBox>
              </Card>
            </MDBox>
            {typeof orderDetails === "object" && orderDetails !== null ? (
              <OrderPhotos
                orderID={orderDetails.id}
                orderPhotos={orderDetails.order_photos}
              />
            ) : null}
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <OrderActivities />
          </Grid>
        </Grid>
        <SpeedDial
          ariaLabel="Order Details"
          sx={{ position: "fixed", bottom: 16, right: 16 }}
          icon={
            <Icon fontSize="small" color="inherit">
              settings
            </Icon>
          }
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
        >
          <SpeedDialAction
            key="print"
            icon={
              <Icon fontSize="small" color="inherit">
                print
              </Icon>
            }
            tooltipTitle="Print"
            onClick={handlePrintOrder}
          />
        </SpeedDial>
        <MDBox sx={{ display: "none" }}>
          {typeof orderDetails === "object" && orderDetails !== null && (
            <PrintableContents orderDetails={orderDetails} ref={componentRef} />
          )}
          ;
        </MDBox>
      </MDBox>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Footer />
    </DashboardLayout>
  );
};

export default OrderDetails;
