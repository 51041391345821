import { useEffect, useState } from "react";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// @mui lab
import LoadingButton from "@mui/lab/LoadingButton";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";

// HTTP Service
import HttpService from "services/http-service";

const StatusDialog = ({
  status = 0,
  orderID = 0,
  statusOptions = [],
  openDialog = false,
  onSuccessUpdate = () => { },
  onCloseDialog = () => { },
}) => {
  const [open, setOpen] = useState(openDialog || false);
  const [statusValue, setStatusValue] = useState(status || 3);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleCloseStatusDialog = () => {
    onCloseDialog();
  };

  const handleStatusChange = (event) => {
    setStatusValue(event.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoadingButton(true);
    const response = await HttpService.put("/kitchen/orders/" + orderID, {
      status: statusValue,
    });

    if (response.status === 201) {
      handleCloseStatusDialog();
      onSuccessUpdate(orderID, { status: parseInt(statusValue, 10) });
    }

    setOpen(false);
    setLoadingButton(false);
  };

  useEffect(() => {
    setOpen(openDialog);
    setStatusValue(status);
  }, [status, orderID, openDialog]);

  return (
    <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      <Dialog
        open={open}
        onClose={handleCloseStatusDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <MDBox component="form" onSubmit={handleFormSubmit}>
          <DialogTitle>Order Status</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} pt={1}>
              <Grid item xs={12}>
                <MDInput
                  select
                  placeholder="Status"
                  value={statusValue}
                  label="Status"
                  fullWidth
                  onChange={handleStatusChange}
                  variant="outlined"
                >
                  {statusOptions.map(
                    (option) =>
                      option.value !== "-1" && (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ),
                  )}
                </MDInput>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseStatusDialog}
              color="secondary"
              variant="text"
            >
              Close
            </MDButton>
            <MDButton
              color="primary"
              variant="contained"
              type="submit"
              component={LoadingButton}
              loading={loadingButton}
            >
              <span>Save changes</span>
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
};

StatusDialog.propTypes = {
  openDialog: PropTypes.bool,
  status: PropTypes.number,
  orderID: PropTypes.number,
  statusOptions: PropTypes.array,
  onSuccessUpdate: PropTypes.func,
  onCloseDialog: PropTypes.func,
};

StatusDialog.displayName = "StatusDialog";

export default StatusDialog;
