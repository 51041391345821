// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import PageLayout from "components/LayoutContainers/PageLayout";

function IllustrationLayout({ image, children }) {
  return (
    <PageLayout>
      <Grid container spacing={3} alignItems="center">
        <Grid size={{ xs: 12, lg: 6 }}>
          <MDBox>
            <MDBox
              display={{ xs: "none", lg: "flex" }}
              width="calc(100% - 2rem)"
              height="calc(100vh - 2rem)"
              borderRadius="lg"
              ml={2}
              mt={2}
              sx={{
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
          </MDBox>
        </Grid>
        <Grid
          size={{ xs: 12, lg: 6 }}
          xs={12}
          lg={6}
          ml={{ xs: "auto" }}
          mr={{ xs: "auto" }}
        >
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {children}
          </MDBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default IllustrationLayout;
