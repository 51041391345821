import { useContext, useState } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import bgImage from "assets/images/skybrew-signin.jpeg";
import logo from "assets/images/logo.png";

import AuthService from "services/auth-service";
import { AuthContext } from "context";

const Login = () => {
  const authContext = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [credentialsErrors, setCredentialsError] = useState(null);
  const [inputs, setInputs] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    // Reset error state when the user is typing
    setErrors({ emailError: false, passwordError: false });
    setCredentialsError(null);
  };

  const validateInputs = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({ ...errors, emailError: true });
      return false;
    }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return false;
    }

    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setIsLoading(true);
    const newUser = { email: inputs.email, password: inputs.password };

    try {
      const response = await AuthService.login(newUser);
      authContext.login(response);
      setIsLoading(false);
    } catch (error) {
      setErrors({ ...errors, emailError: true, passwordError: true });

      if (error && error.data?.error?.message) {
        setCredentialsError(error.data.error.message);
      } else {
        // use snackbar here.
        setCredentialsError(
          "Oops! Our servers are taking a coffee break ☕️. We'll brew up a fix ASAP. Try again in a bit!.",
        );
      }
      setIsLoading(false);
    }
  };

  return (
    <IllustrationLayout image={bgImage}>
      <MDBox display="flex" justifyContent="center" sx={{ zIndex: "99" }}>
        <MDBox>
          <MDBox component="img" src={logo} alt="SkyBrew" width="20rem" />
          <MDTypography
            variant="h3"
            paddingTop="2rem"
            style={{ color: "#344767", fontWeight: 700, textAlign: "center" }}
          >
            Sign In
          </MDTypography>
          <MDTypography
            display="block"
            variant="button"
            style={{ color: "#7b809a", textAlign: "center" }}
            my={1}
          >
            Enter your email and password to sign in
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display="block" justifyContent="center">
        {credentialsErrors && (
          <MDTypography
            variant="caption"
            color="error"
            style={{ fontWeight: 700, width: "80%" }}
            fontSize="1rem"
          >
            {credentialsErrors}
          </MDTypography>
        )}
      </MDBox>
      <MDBox px={1} mx="auto" paddingTop="1rem" width="100%">
        <MDBox pt={2} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={submitHandler}
          >
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                color={isLoading ? "secondary" : "info"}
                disabled={isLoading}
                fullWidth
                type="submit"
              >
                {isLoading ? "Signing in..." : "Sign in"}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
};

export default Login;
