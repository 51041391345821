import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const EditUser = forwardRef(({ onSuccessUpdated = () => {} }, ref) => {
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [userTypeId, setUserType] = useState(2);
  const [userStatus, setUserStatus] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    email: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleUserTypeChange = (event) => {
    setUserType(Number(event.target.value) || 1);
  };

  const handleUserStatusChange = (event) => {
    setUserStatus(event.target.checked);
  };

  const handleCloseUpdateUser = () => {
    setOpen(false);
  };

  const handleOpenUpdateUser = () => {
    setOpen(true);
  };

  const validateInputs = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let hasErrors = false;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        email: "Invalid email address",
      }));
      hasErrors = true;
    }

    if (inputs.name.trim().length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, name: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        name: "Invalid name. Must be at least 6 characters",
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const requestUpdateUser = async () => {
    setLoadingButton(true);

    try {
      let postData = {
        name: inputs.name,
        user_type_id: userTypeId,
        status: userStatus ? 1 : 2,
      };

      const response = await HttpService.put(
        "/kitchen/users/" + userId,
        postData,
      );

      if (response.status === 201) {
        setInputs({
          name: "",
          email: "",
        });

        setErrors({
          name: false,
          email: false,
        });

        setErrorMessages({
          name: "",
          email: "",
        });

        handleCloseUpdateUser();
        onSuccessUpdated();
      }

      setLoadingButton(false);
    } catch (e) {
      console.log(e);
      setLoadingButton(false);
    }
  };

  const handleUpdateUserFormSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    requestUpdateUser();
  };

  const openEditDialog = async (userId) => {
    setUserId(userId);
    setLoadingScreen(true);

    try {
      const response = await HttpService.get("/kitchen/users/" + userId);

      setInputs({
        name: response.data.name,
        email: response.data.email,
      });

      setErrors({
        name: false,
        email: false,
      });

      setErrorMessages({
        name: "",
        email: "",
      });

      if (response.data.status === 1) {
        setUserStatus(true);
      } else {
        setUserStatus(false);
      }

      setUserType(response.data.user_type_id);
      setLoadingScreen(false);
      handleOpenUpdateUser();
    } catch (e) {
      console.log(e);
    }
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openEditDialog,
  }));

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
        <Dialog
          open={open}
          onClose={handleCloseUpdateUser}
          fullWidth={true}
          maxWidth="sm"
        >
          <MDBox component="form" onSubmit={handleUpdateUserFormSubmit}>
            <DialogTitle>Update User</DialogTitle>
            <DialogContent>
              <Grid container spacing={3} pt={1}>
                <Grid size={12}>
                  <MDInput
                    name="name"
                    label="Name"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.name}
                    error={errors.name}
                    helperText={errorMessages.name}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <MDInput
                    readOnly
                    name="email"
                    label="Email address"
                    fullWidth
                    type="email"
                    value={inputs.email}
                    error={errors.email}
                    helperText={errorMessages.email}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <MDInput
                    name="user_type_id"
                    label="User Type"
                    fullWidth
                    select
                    value={userTypeId}
                    onChange={handleUserTypeChange}
                  >
                    <MenuItem value={2}>Administrator</MenuItem>
                    <MenuItem value={3}>Staff</MenuItem>
                  </MDInput>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <MDBox mx={1}>
                    <FormControlLabel
                      control={<Switch color="primary" />}
                      label="Active"
                      checked={userStatus}
                      onChange={handleUserStatusChange}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={handleCloseUpdateUser}
                color="secondary"
                variant="text"
              >
                Close
              </MDButton>
              {loadingButton ? (
                <CircularProgress />
              ) : (
                <MDButton color="primary" type="submit">
                  Save changes
                </MDButton>
              )}
            </DialogActions>
          </MDBox>
        </Dialog>
      </MDBox>
    </>
  );
});

EditUser.propTypes = {
  onSuccessUpdated: PropTypes.func,
};

EditUser.displayName = "EditUser";

export default EditUser;
