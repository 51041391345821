import React, { forwardRef, useEffect } from "react";

import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid2";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const OrdersPrintable = forwardRef(({ orders, onRender }, ref) => {
  const spanLabelStyles = {
    position: "absolute",
    top: -12,
    left: 0,
    fontSize: "0.8rem",
    fontWeight: "100",
  };

  useEffect(() => {
    onRender(true);
  }, [onRender]);

  return (
    <div ref={ref} className="printable-content">
      {orders.map((orderDetails) => (
        <MDBox
          key={orderDetails.id}
          className="page-break"
          sx={{ display: { xs: "block", displayPrint: "block" } }}
        >
          <Grid container spacing={2}>
            <Grid size={12}>
              <MDTypography variant="h3" sx={{ textAlign: "center" }}>
                {orderDetails.external_order_id}
              </MDTypography>
            </Grid>
            <Grid size={12}>
              <MDTypography variant="h5">
                {orderDetails.customer_name}
              </MDTypography>
              <MDTypography variant="body2">
                {orderDetails.shipping_address}
              </MDTypography>
            </Grid>
            <Grid size={4}>
              <MDTypography variant="h4" sx={{ position: "relative" }}>
                {dayjs(orderDetails.delivery_date).format("MM/DD/YYYY")}
                <span style={spanLabelStyles}>Delivery Date</span>
              </MDTypography>
            </Grid>
            <Grid size={4}>
              <MDTypography variant="h4" sx={{ position: "relative" }}>
                {dayjs(`1970-01-01 ${orderDetails.dispatch_time}`).format(
                  "hh:mmA",
                )}
                <span style={spanLabelStyles}>Dispatch Time</span>
              </MDTypography>
            </Grid>
            <Grid size={4}>
              <MDTypography variant="h4" sx={{ position: "relative" }}>
                {dayjs(`1970-01-01 ${orderDetails.delivery_eta}`).format(
                  "hh:mmA",
                )}
                <span style={spanLabelStyles}>Delivery Time</span>
              </MDTypography>
            </Grid>
            <Grid size={12}>
              {orderDetails.notes ? (
                <MDTypography variant="body2">
                  Notes : {orderDetails.notes}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid size={12}>
              <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
                <Table aria-label="order lines table">
                  <TableBody>
                    {orderDetails.order_line_items.map(
                      (orderLineItem, index) => (
                        <React.Fragment
                          key={`${orderLineItem.id}-${index}-item`}
                        >
                          {orderLineItem.subitems.length === 0 &&
                          !orderLineItem.item_description
                            .toLowerCase()
                            .includes("upgrade") ? (
                            <TableRow
                              key={`${orderLineItem.id}-${index}-xitem`}
                            >
                              <TableCell scope="row">
                                <MDTypography variant="body2">
                                  {orderLineItem.item_quantity} x{" "}
                                  {orderLineItem.item_description}
                                </MDTypography>
                              </TableCell>
                            </TableRow>
                          ) : null}

                          {orderLineItem.subitems.length !== 0 &&
                            orderLineItem.subitems.map((subitem, subIndex) => (
                              <TableRow
                                key={`${subitem.id}-${subIndex}-subitem`}
                              >
                                <TableCell scope="row">
                                  <MDTypography variant="body2">
                                    {subitem.item_quantity} x{" "}
                                    {subitem.item_description}
                                  </MDTypography>
                                </TableCell>
                              </TableRow>
                            ))}
                        </React.Fragment>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </MDBox>
      ))}
    </div>
  );
});

OrdersPrintable.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      external_order_id: PropTypes.number.isRequired,
      customer_name: PropTypes.string.isRequired,
      notes: PropTypes.string,
      delivery_date: PropTypes.string.isRequired,
      dispatch_time: PropTypes.string.isRequired,
      delivery_eta: PropTypes.string.isRequired,
      order_line_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          item_quantity: PropTypes.number.isRequired,
          item_description: PropTypes.string.isRequired,
          subitems: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              item_quantity: PropTypes.number.isRequired,
              item_description: PropTypes.string.isRequired,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
  onRender: PropTypes.func.isRequired,
};

OrdersPrintable.displayName = "OrdersPrintable";

export default OrdersPrintable;
