import React, { useState, useRef } from "react";

import { useReactToPrint } from "react-to-print";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// @mui material components
import {
  AppBar,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import PhotoUploader from "components/PhotoUploader";
import OrderDetailsPrintable from "./../OrderDetailsPrintable";

const OrderDetailsDialog = ({
  open,
  handleCloseOrderDetails,
  orderDetails,
  checkedOrderLines,
  handleOrderLineCheckboxChange,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(1);
  const handleSetTabValue = (_event, newValue) => setTabValue(newValue);

  const orderDetailsPrintableRef = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Order Details",
    contentRef: orderDetailsPrintableRef,
  });

  const spanLabelStyles = {
    position: "absolute",
    top: -12,
    left: 0,
    fontSize: "0.8rem",
    fontWeight: "100",
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={tabValue !== index}
        id={`order-details-tabpanel-${index}`}
        aria-labelledby={`order-details-tab-${index}`}
        {...other}
      >
        {value === index && <MDBox>{children}</MDBox>}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `order-details-tab-${index}`,
      "aria-controls": `order-details-tabpanel-${index}`,
    };
  }

  return (
    <Dialog
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={handleCloseOrderDetails}
      className="printable-content"
    >
      {orderDetails ? (
        <>
          <MDBox
            position="absolute"
            top={5}
            right={5}
            p={1.625}
            onClick={handleCloseOrderDetails}
            className="not-printable-content"
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h4" color="secondary">
              <Icon
                className="not-printable-content"
                sx={{ fontWeight: "bold" }}
              >
                close
              </Icon>
            </MDTypography>
          </MDBox>
          <MDBox
            position="absolute"
            top={5}
            right={40}
            p={1.625}
            onClick={handlePrint}
            className="not-printable-content"
            sx={{ cursor: "pointer" }}
          >
            <MDTypography variant="h4" color="secondary">
              <Icon className="not-printable-content">print</Icon>
            </MDTypography>
          </MDBox>
          <DialogTitle sx={{ fontSize: "2rem", textAlign: "center" }}>
            {orderDetails.external_order_id}
          </DialogTitle>
          <DialogContent>
            <AppBar position="static">
              <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab label="Details" value={1} {...a11yProps(1)} />
                <Tab label="Photos" value={2} {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <MDBox mt={4}>
              <TabPanel value={1} index={1}>
                <MDTypography variant="h5">
                  {orderDetails.customer_name}
                </MDTypography>
                <MDTypography variant="body2">
                  {orderDetails.shipping_address}
                </MDTypography>
                <Grid container spacing={2} mt={4}>
                  <Grid size={4}>
                    <MDTypography variant="h4" sx={{ position: "relative" }}>
                      {dayjs(orderDetails.delivery_date).format("MM/DD/YYYY")}
                      <span style={spanLabelStyles}>Delivery Date</span>
                    </MDTypography>
                  </Grid>
                  <Grid size={4}>
                    <MDTypography variant="h4" sx={{ position: "relative" }}>
                      {dayjs(`1970-01-01 ${orderDetails.dispatch_time}`).format(
                        "hh:mmA",
                      )}
                      <span style={spanLabelStyles}>Dispatch Time</span>
                    </MDTypography>
                  </Grid>
                  <Grid size={4}>
                    <MDTypography variant="h4" sx={{ position: "relative" }}>
                      {dayjs(`1970-01-01 ${orderDetails.delivery_eta}`).format(
                        "hh:mmA",
                      )}
                      <span style={spanLabelStyles}>Delivery Time</span>
                    </MDTypography>
                  </Grid>
                  <Grid size={12}>
                    {orderDetails.notes ? (
                      <MDTypography
                        variant="body2"
                        sx={{ position: "relative" }}
                      >
                        {orderDetails.notes}
                        <span style={spanLabelStyles}>Notes</span>
                      </MDTypography>
                    ) : null}
                  </Grid>
                  <Grid size={12}>
                    <List sx={{ width: "100%" }}>
                      {orderDetails.order_line_items.map((orderLineItem) => (
                        <React.Fragment
                          key={`${orderLineItem.id}-key-fragment`}
                        >
                          {orderLineItem.subitems.length === 0 &&
                          !orderLineItem.item_description
                            .toLowerCase()
                            .includes("upgrade") ? (
                            <ListItem disablePadding>
                              <ListItemButton
                                onClick={() =>
                                  handleOrderLineCheckboxChange(
                                    orderLineItem.id,
                                  )
                                }
                                dense
                              >
                                <Checkbox
                                  color="primary"
                                  checked={checkedOrderLines.includes(
                                    orderLineItem.id,
                                  )}
                                  onChange={() =>
                                    handleOrderLineCheckboxChange(
                                      orderLineItem.id,
                                    )
                                  }
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                />
                                <ListItemText
                                  primary={`${orderLineItem.item_quantity} x ${orderLineItem.item_description}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          ) : null}

                          {orderLineItem.subitems.length !== 0 &&
                            orderLineItem.subitems.map((subitem) => (
                              <ListItem key={subitem.id} disablePadding>
                                <ListItemButton
                                  onClick={() =>
                                    handleOrderLineCheckboxChange(subitem.id)
                                  }
                                  dense
                                >
                                  <Checkbox
                                    color="primary"
                                    checked={checkedOrderLines.includes(
                                      subitem.id,
                                    )}
                                    onChange={() =>
                                      handleOrderLineCheckboxChange(subitem.id)
                                    }
                                    edge="start"
                                    tabIndex={-1}
                                    disableRipple
                                  />
                                  <ListItemText
                                    primary={`${subitem.item_quantity} x ${subitem.item_description}`}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                        </React.Fragment>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={2} index={2}>
                <PhotoUploader
                  orderID={orderDetails.id}
                  orderPhotos={orderDetails.order_photos}
                />
              </TabPanel>
            </MDBox>
          </DialogContent>
          <MDBox sx={{ display: "none" }}>
            <OrderDetailsPrintable
              ref={orderDetailsPrintableRef}
              orderDetails={orderDetails}
            />
          </MDBox>
        </>
      ) : null}
    </Dialog>
  );
};

OrderDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseOrderDetails: PropTypes.func.isRequired,
  orderDetails: PropTypes.object,
  checkedOrderLines: PropTypes.array.isRequired,
  handleOrderLineCheckboxChange: PropTypes.func.isRequired,
};

export default OrderDetailsDialog;
